<template>
	<main id="paxExpress" class="pax_global">
		<div id="paxReverse" class="container-fluid">
			<div class="row justify-content-center">
				<ChoixLangue v-show="step === 1" @langue_choosed="goToCountry"/>
				<ChoixCountry v-show="step === 2" @country_choosed="goToPax"/>
				<template v-if="step > 2">
					<div class="col bstallion">
						<MaresSuggestion :mares_selected.sync="mares_selected" :pax_suggestion="true"></MaresSuggestion>
					</div>
				</template>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">
    import Constants from 'Constants'
	import UserMixin from '@/mixins/User.js'
	import Navigation from '@/mixins/Navigation.js'
	import PaxMixin from '@/mixins/Pax.js'
	import Config from '@/mixins/Config.js'
	import Window from '@/mixins/Window.js'
	import _uniqueId from 'lodash/uniqueId'

	export default {
		name: "PaxReverse",
		mixins: [UserMixin, Navigation, PaxMixin, Config, Window],
		data () {
			return {
				mares_selected: [],
				stallion_selected: null,
				criteres: [],
				mare: {},
				force_refresh: false,
				mare_graphique: null,
				step: 1,
				step_label: "",
				mares_comparaison: [],
				steps: {'comparaison_V': 5, 'mares_selected': 4, 'stallions': 3, 'country': 2, 'pax_graphique': 6},
			}
        },
		created() {
            this.init_component()
		},
		methods: {
			async init_component() {
				document.querySelector('html').classList.remove('no_scroll')
				this.mares_selected = []
				this.stallion_selected = null
				this.criteres = []
				// au reload permet de mettre à jour les critères à l'étape pax
				this.force_refresh = _uniqueId()

				const lang = this.getConfig('lang')
				const country = this.getConfig('country')
				if(lang && country) {
					this.step = 3
				}
				else if(lang && !country) {
					this.step = 2
				}
				else {
					this.step = 1
				}
			},

			async goToStep(step_to_go) {
				if(step_to_go < this.step) {
					this.step_label = Object.keys(this.steps).find(key => this.steps[key] === step_to_go)
				}
			},

			goToCountry() {
				this.step = 2
			},

			goToPax() {
				this.step = 3
			},

			goToHome() {
				this.init_component()
			},

			openMenu() {
				this.$store.commit('nav/open')
			},

			async getStallionCritere() {
				if(this.stallion_selected && this.stallion_selected.horse_id) {
					const criteres_horse = await this.getCaracterisationByHorse(this.stallion_selected.horse_id)
					const criteres = await this.getCriteres(this.lang)

					let criteres_horse_info = []
					criteres.forEach(critere => {
						let horse = criteres_horse.find(c => c.caracterisation_criteres == critere.criteres_id)
						criteres_horse_info.push({
							criteres_id: critere.criteres_id,
							criteres_max: critere.criteres_max,
							criteres_min: critere.criteres_min,
							criteres_red: critere.criteres_red,
							criteres_green: critere.criteres_green,
							critereslang_label: critere.criteres_lang[0].critereslang_label,
							slider: horse.caracterisation_valeur/100
						})
					})
					this.criteres = criteres_horse_info
				}
			}
		},
		computed: {
			lang() {
				return this.getConfig('lang') ?? false
			},
			country() {
				return this.getConfig('country') ?? false
			},
		},
		watch: {
			step_label(val) {
				this.step = this.steps[val]
			},
			stallion_selected(val) {
				this.getStallionCritere()
			}
		},
		components: {
			MaresSuggestion : () => import('@/components/Pax/MaresSuggestion'),
			ChoixLangue: () => import('@/components/Pax/ChoixLangue'),
			ChoixCountry: () => import('@/components/Pax/ChoixCountry'),
		}
	};
</script>
